import React from "react"
import { navigate } from 'gatsby'

// markup
const NotFoundPage = () => {
  React.useEffect(() => {
    navigate("/")
  }, [])

  return null;
}

export default NotFoundPage
